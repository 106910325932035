import { Link } from "gatsby"
import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout textblock={true}>
    <SEO title="404: Page not found" description="Unfortunately, we could not find the page you were looking for" />
    <section className="item">
      <h1>Page not found</h1>
      <p>
        Unfortunately, we could not find the page you were looking for. If you were trying to redeem a download code,
        please continue to our <Link to="https://nutriot.bandcamp.com/yum" external={true}>Bandcamp</Link> page.
      </p>
      <p>
        <Link href="/">Return to home page</Link>.
      </p>
    </section>
  </Layout>
)

export default NotFoundPage
